/* eslint-disable @typescript-eslint/no-unused-vars */
// normalize CSS across browsers
import './static/styles/normalize.css';
// custom CSS styles
import './static/styles/layout.css';

// eslint-disable-next-line no-unused-vars
import React from 'react';
import { AnimatePresence } from 'framer-motion';
export const wrapPageElement = ({ element }) => (
  <AnimatePresence mode="wait">{element}</AnimatePresence>
);
